import { UserRoles } from "./User";
import { EmailTemplate } from "./Email";

export enum SurveyStateEnum {
  Draft = "draft",
  PaymentPending = "payment-pending",
  Active = "active",
  InActive = "inactive",
}

export type SurveyStats = {
  completedUsers: number;
  optedOutUsers: number;
};

export type SurveyType = {
  _id: string;
  externalId: string;
  title: string;
  image: string;
  createdAt: Date;
  start: Date;
  end: Date;
  likes: number;
  source: SurveySource;
  category: string;
  points: number;
  tags: string[];
  externalUrl?: string;
  targetingTags: string[];
  targetingSurveyToUsers: string;
  active: boolean;
  internalParamsConfigured: boolean;
  minResponseRate: number;
  maxResponseRate: number;
  enableSpinner: boolean;
  spinnerProbability: number;
  targetUsers: string[];
  shouldNotifyUsers: boolean;
  payload: Record<string, any>;
  isPinned: boolean;
  excludedTargetingTags: string[];
  notificationSchedules?: NotificationSchedule[];
  externalSurveySourceData?: SourceData | null;
  externalSurveyRedirectLinksData?: RedirectLinksData | null;
  externalSurveyRedirectPointsData?: RedirectPointsData | null;
  limits?: SurveyLimits;
  state?: SurveyStateEnum;
  createdByRole?: UserRoles;
  stats?: SurveyStats;
  responsesWeightReport?: string;
  activationRedirects?: ActivationRedirect[];
};

export type SurveyFeedType = SurveyType & { completed: boolean; isLiked: boolean };

export type PointsTableData = {
  orderId: number;
  parameterName: string;
  parameterType: string;
  value: string;
  arguments?: string[];
};

export type RedirectPointsData = {
  parameters: PointsTableData[];
};

export type RedirectLinksData = {
  baseUrl: string;
  parameters: TableData[];
};

export type ActivationRedirect = {
  vsref: string;
  virtualId: string;
  activationLink?: string;
};

export type TableData = {
  orderId: number;
  parameterName: string;
  parameterType: string;
  value: string;
  arguments?: [];
};

export enum SurveyLinkTableParamEnum {
  LITERAL_STRING = "LITERAL_STRING",
  LITERAL_NUMERIC = "LITERAL_NUMERIC",
  SHA1_HASH = "SHA1_HASH",
  USER_ID = "USER_ID",
  SURVEY_ID = "SURVEY_ID",
}

export type SourceData = {
  baseUrl: string;
  parameters: TableData[];
};

export enum SurveySource {
  TYPEFORM = "typeform",
  QUALTRICS = "qualtrics",
  EXTERNAL = "external",
}

export enum SurveysSyncSource {
  TYPEFORM = "typeform",
  QUALTRICS = "qualtrics",
}

export enum NotificationsTagTypeEnum {
  ALL = "all",
  TAG = "tag",
}

export const PREDEFINED_TAGS = {
  allForSurvey: "All for Survey",
  allowNonGiftCardCashouts: "allow_non_gc_cashouts",
};

export type QuerySurveysDto = {
  ids: string;
  fields: string;
};

export enum SurveyIncludesEnum {
  stats = "stats",
}

export enum TargetingSurveyToUsersEnum {
  ALL = "targetingAllUsers",
  TAG = "targetingTagAutoComplete",
  CSV = "csv",
}

export interface SurveyLimits {
  sampleSize: number;

  matrixQuestions: number;

  openEndedQuestions: number;

  multipleChoiceQuestions: number;
  discountCode?: string;
  discount?: number;
}

export type NewSurveyNotificationsPayload = {
  singleEmailToNotify?: string;
  notificationsTagType: NotificationsTagTypeEnum;
  notificationsTagValue: string;
  forceNotifications: boolean;
  emailTemplate: EmailTemplate;
  scheduledAt?: Date;
  scheduleId?: string;
};

export interface NotificationSchedule {
  _id: string;
  scheduledAt: string;
  scheduleOptions: Omit<NewSurveyNotificationsPayload, "scheduledAt">;
}

export enum SurveyNotificationLogStatus {
  Scheduled = "Scheduled",
  Failed = "Failed",
  Complete = "Complete",
  PartiallyFailed = "PartiallyFailed",
  Cancelled = "Cancelled",
}

export interface SurveyNotificationLog {
  _id: string;
  payload: NewSurveyNotificationsPayload;
  surveyId: string;
  createdAt: string;
  status: SurveyNotificationLogStatus;
}
