import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Button,
} from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "@mui/lab";
import "./RightColumnFields.scss";
import { useEffect, useState } from "react";
import _ from "lodash";
import { TargetingTag, UTM_TARGETING_TAG_PREFIX } from "../../../../types/TargetingTag";
import TargetingTagsService from "../../../../services/api/targetingTagsService";
import { AxiosResponse } from "axios";
import { atom, useAtom } from "jotai";
import { TargetingSurveyToUsersEnum } from "../../../../types/Survey";
import TargetingTagInput from "./TargetingTagInput";
import CsvDialog from "./CsvDialog";
import ExcludeTagsInput from "./ExcludeTagsInput";
import { UtmApplyingLogic } from "../EditSurvey";
import styled from "@emotion/styled";

export const utmCampaignTargetingTagAtom = atom<string>("");

const UploadCsvButton = styled.button({
  width: "max-content",
  border: "none",
  backgroundColor: "transparent",
});

const RightColumnFields = ({
  usersFile,
  setUsersFile,
  availableTargetingTags,
  targetingTags,
  setTargetingTags,
  setTargetingSurveyToUsers,
  targetingSurveyToUsers,
  publish,
  AdapterDateFns,
  targetUsersAfterDate,
  setTargetUsersAfterDate,
  csvTag,
  setCsvTag,
  excludeGlobalTags,
  setExcludeGlobalTags,
  excludedTargetingTags,
  setExcludedTargetingTags,
  utmApplyingLogic,
  setUtmApplyingLogic,
}: any) => {
  const [csvDialogOpen, setCsvDialogOpen] = useState(false);
  const [csvTagInternal, setCsvTagInternal] = useState<string | undefined>(undefined);
  const [csvDialogOkDisabled, setCsvDialogOkDisabled] = useState<boolean>(true);

  const [utmCampaignTags, setUtmCampaignTags] = useState<TargetingTag[]>([]);
  const [selectedUtmCampaignTag, setSelectedUtmCampaignTag] = useAtom(utmCampaignTargetingTagAtom);

  const [createUtmDialogOpen, setCreateUtmDialogOpen] = useState(false);

  useEffect(() => {
    TargetingTagsService.getUtmTargetingTags().then((res: AxiosResponse) => {
      setUtmCampaignTags(res.data);
    });
  }, []);

  useEffect(() => {
    setCsvDialogOkDisabled(_.isNil(usersFile) || _.isEmpty(csvTagInternal?.trim()));
  }, [usersFile, csvTagInternal]);

  const openCsvDialog = () => {
    setCsvTagInternal(csvTag);
    setCsvDialogOpen(true);
  };

  const cancelCsvDialog = () => {
    setUsersFile(undefined);
    setCsvTag("");
  };

  const onChangeCsvTag = (_evt: any, newValue: any) => {
    setCsvTag(newValue);
  };

  return (
    <div>
      <Divider sx={{ marginY: "24px" }} />

      <h3 className="target-container__title">Target this survey to an audience</h3>
      <div className="last-container">
        <Box className="input-container-survey multiplier-block " style={{ marginBottom: 0 }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="radio-buttons-group"
              value={targetingSurveyToUsers}
            >
              <Box sx={{ marginBottom: "1rem", maxWidth: "100%" }}>
                <div className="target-survey-block">
                  <FormControl fullWidth>
                    <InputLabel id="utm-campaign-tags-label">Optional utm campaign</InputLabel>
                    <Select
                      labelId="utm-campaign-tags-label"
                      id="utm-campaign-tags"
                      defaultValue=""
                      value={utmCampaignTags.length > 0 ? selectedUtmCampaignTag : ""}
                      onChange={(e) => setSelectedUtmCampaignTag(e.target.value)}
                      label="Optional utm campaign"
                    >
                      <MenuItem value="create-utm" onClick={() => setCreateUtmDialogOpen(true)}>
                        Create UTM
                      </MenuItem>
                      <MenuItem value="">N/A</MenuItem>
                      <MenuItem value="all">All</MenuItem>
                      {utmCampaignTags.map((tag: TargetingTag) => (
                        <MenuItem key={tag._id.toString()} value={tag._id.toString()}>
                          {tag.tag.split(UTM_TARGETING_TAG_PREFIX)[1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="utm-tag-applying-logic">UTM Targeting Logic</InputLabel>
                    <Select
                      labelId="utm-tag-applying-logic"
                      id="utm-tag-logic"
                      value={utmApplyingLogic}
                      label="UTM Targeting Logic"
                      onChange={(event) => setUtmApplyingLogic(event.target.value as string)}
                    >
                      <MenuItem value={UtmApplyingLogic.AND}>AND</MenuItem>
                      <MenuItem value={UtmApplyingLogic.OR}>OR</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <Dialog
                open={createUtmDialogOpen}
                onClose={() => setCreateUtmDialogOpen(false)}
                PaperProps={{
                  component: "form",
                  onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries((formData as any).entries());
                    const response = await TargetingTagsService.createUtmTag(formJson.utmTag);
                    const tag = response.data;
                    const tagId = tag._id;
                    setCreateUtmDialogOpen(false);
                    setUtmCampaignTags([...utmCampaignTags, tag]);
                    setSelectedUtmCampaignTag(tagId);
                  },
                }}
              >
                <DialogTitle>Create UTM Targeting Tag</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="utm-campaign-tag-name"
                    name="utmTag"
                    label="UTM Campaign Tag Name"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setCreateUtmDialogOpen(false)}>Cancel</Button>
                  <Button type="submit">Create</Button>
                </DialogActions>
              </Dialog>

              <div className="target-survey-block">
                <FormControlLabel
                  onChange={() => setTargetingSurveyToUsers(TargetingSurveyToUsersEnum.ALL)}
                  value={TargetingSurveyToUsersEnum.ALL}
                  control={<Radio color="primary" />}
                  label="All"
                />
                <div className="input-container-survey">
                  <div className="input-label">After signup date</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={targetUsersAfterDate ? new Date(targetUsersAfterDate) : null}
                      onChange={(newValue: any) => {
                        setTargetUsersAfterDate(newValue);
                      }}
                      disabled={targetingSurveyToUsers !== TargetingSurveyToUsersEnum.ALL}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <FormControlLabel
                onChange={() => setTargetingSurveyToUsers(TargetingSurveyToUsersEnum.TAG)}
                value={TargetingSurveyToUsersEnum.TAG}
                control={<Radio color="primary" />}
                label={
                  <Box className="input-container-survey">
                    <div className="target-box__label">Tag based targeting</div>
                  </Box>
                }
              />

              <TargetingTagInput
                targetingTags={targetingTags}
                setTargetingTags={setTargetingTags}
                availableTargetingTags={availableTargetingTags}
                targetingSurveyToUsers={targetingSurveyToUsers}
                csvTag={csvTag}
                onChangeCsvTag={onChangeCsvTag}
              />

              <UploadCsvButton
                onClick={targetingSurveyToUsers === TargetingSurveyToUsersEnum.TAG ? openCsvDialog : () => false}
                type="button"
              >
                <div
                  className="upload-users-container"
                  style={{
                    paddingTop: "0.5em",
                    width: "100%",
                    justifyContent: "left",
                  }}
                >
                  <label
                    className="upload-users-label"
                    htmlFor="upload-users"
                    style={
                      targetingSurveyToUsers !== TargetingSurveyToUsersEnum.TAG
                        ? { cursor: "default", backgroundColor: "#5f6f74", border: "1px solid #5f6f74" }
                        : {}
                    }
                  >
                    <p>Upload CSV</p>
                  </label>
                </div>
              </UploadCsvButton>

              <CsvDialog
                cancelCsvDialog={cancelCsvDialog}
                csvDialogOpen={csvDialogOpen}
                availableTargetingTags={availableTargetingTags}
                csvTagInternal={csvTagInternal}
                usersFile={usersFile}
                setUsersFile={setUsersFile}
                targetingSurveyToUsers={targetingSurveyToUsers}
                csvDialogOkDisabled={csvDialogOkDisabled}
                setCsvTagInternal={setCsvTagInternal}
                setCsvDialogOpen={setCsvDialogOpen}
                onChangeCsvTag={onChangeCsvTag}
              />

              <Box sx={{ marginTop: "1rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => setExcludeGlobalTags(event.target.checked)}
                      checked={excludeGlobalTags}
                    />
                  }
                  label="Exclude those with global tags"
                />

                <ExcludeTagsInput
                  excludeGlobalTags={excludeGlobalTags}
                  allTargetingTags={availableTargetingTags}
                  excludedTargetingTags={excludedTargetingTags}
                  setExcludedTargetingTags={setExcludedTargetingTags}
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>

        <button type="button" onClick={publish} className="last-container__button">
          Save Information
        </button>
      </div>
    </div>
  );
};
export default RightColumnFields;
