import { Dialog, IconButton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VeraButton from "../../../../components/VeraButton";
import { checkIfThereAreInvalidEmails } from "../../../../helpers/generic";

const UsersBulkDeleteDialog = ({ onClose, onSubmit, open, setCSVFileParsed, CSVFileParsed }: any) => {
  const handleClose = () => {
    onClose();
  };
  const removeRow = (index: number) => {
    let newCSVileParsed = [...CSVFileParsed];
    newCSVileParsed.splice(index, 1);
    setCSVFileParsed(newCSVileParsed);
    if (newCSVileParsed.length === 0) {
      handleClose();
    }
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: { borderRadius: 28, maxWidth: "700px" },
        }}
      >
        <div className="dialog-content" style={{ width: "624px" }}>
          <p className="dialog-title">Are you sure you want to delete these users?</p>
          {CSVFileParsed && (
            <Table>
              <TableBody style={{ borderRadius: "16px" }}>
                {CSVFileParsed.map((user: any, index: number) => {
                  if (user.email.length <= 0) return null;

                  return (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell component="th" scope="row">
                        <b>{user.email}</b>
                      </TableCell>
                      <TableCell className={`status-cell ${user.valid ? "active" : "inactive"}`}>
                        {user.valid ? "Valid" : "Invalid"}
                      </TableCell>
                      <TableCell className="actions-button">
                        <IconButton onClick={(event: any) => removeRow(index)}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
          <div className="buttons-wrapper">
            <VeraButton variant="text" onClick={onClose} sx={{ border: "none" }}>
              Cancel
            </VeraButton>
            <VeraButton
              variant="outlined"
              onClick={() => onSubmit()}
              disabled={CSVFileParsed.length === 0 || checkIfThereAreInvalidEmails(CSVFileParsed)}
              className="dialog-upload-button"
            >
              Confirm
            </VeraButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UsersBulkDeleteDialog;
