import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Grid, Paper, InputAdornment } from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";

import { AuthContext } from "../../contexts/authContext";
import { useValidPasswordAdvance } from "../../hooks/useValidPasswordAdvance";
import { useValidCode } from "../../hooks/useValidCode";
import {
  backgroundStyle,
  btnStyle,
  checkListStyle,
  h1Style,
  inputStyle,
  logoStyle,
  paperStyle,
  plabelStyle,
  containerStyle,
  rowStyle,
  checkListItemStyle,
  btnStyleDisabled,
  passwordRecoverySubtitleStyle,
  pleseEnterNewPasswordStyle,
  passwordSubtitleStyle,
} from "./styles";
import { PasswordType } from "../../interfaces/IPassType";
import verasightLogo from "../../assets/landing-page/verasight-logo-community.svg";
import { IconButton, LinearProgress, OutlinedInput, Snackbar, Alert } from "@mui/material";

import UsersService from "../../services/api/usersService";
import SideCarousel from "../../components/SignUpSideCarousel/SignUpSideCarousel";
import { getItemIDB, setItemIDB } from "../../services/indexedDb";
import { isCaptchaEnabled } from "../../helpers/generic";
import { UserRoles } from "../../types/User";
import SnackBarClose from "../../components/common/SnackbarClose";
import { disableKeyDown } from "src/helpers/event-handlers";

type CheckPasswordProps = {
  type: PasswordType;
};

type CustomizedState = {
  text: string;
};

const CheckPassword: React.FC<any> = ({ type }: CheckPasswordProps) => {
  const location = useLocation();
  const locationState = location.state as CustomizedState;

  const { passwordAdvance, setPasswordAdvance, passwordAdvanceIsValid } = useValidPasswordAdvance(null);
  const [confirmPass, setConfirmPass] = useState("");
  const [confirmPassError, setConfirmPassError] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [isOTPValid, setIsOTPValid] = useState(false);
  const [isCaptchaCaptured, setIsCaptchaCaptured] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string>();
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const captchaRef = React.useRef<ReCAPTCHA | null>(null);

  const { code, setCode, codeIsValid } = useValidCode(null);

  const authContext = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);

  /* Generic Error Message */
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const navigation = useNavigate();
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    confirm: false,
  });

  const handleClickShowPassword = (name: "password" | "confirm") => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };

  /*
  Functions Section
  */
  useEffect(() => {
    onComparePassword(confirmPass);
    // eslint-disable-next-line
  }, [confirmPass, passwordAdvance]);

  useEffect(() => {
    if (passwordAdvanceIsValid[0].text === "Password Strength: Good" && confirmPassError === "Passwords match") {
      setIsPasswordValid(true);
      setIsConfirmPasswordValid(true);
    } else if (
      passwordAdvanceIsValid[0].text === "Password Strength: Good" &&
      (confirmPassError === "The passwords you have entered don’t match. Please correct to proceed!" ||
        confirmPassError.length === 0)
    ) {
      setIsPasswordValid(true);
      setIsConfirmPasswordValid(false);
    } else {
      setIsPasswordValid(false);
      setIsConfirmPasswordValid(false);
    }
  }, [passwordAdvanceIsValid, confirmPassError]);

  useEffect(() => {
    getStorageData().catch((e) => console.error(e));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isPasswordValid || !isConfirmPasswordValid) {
      captchaRef.current && captchaRef.current.reset();
    }
  }, [isPasswordValid, isConfirmPasswordValid]);

  const getStorageData = async () => {
    const isOTPScreenOn = await getItemIDB("isOTPScreenOn");
    const signUpUserData = await getItemIDB("signUpUserData");
    if (isOTPScreenOn === "1" && signUpUserData && authContext.setSignUpUserData) {
      authContext.setSignUpUserData(JSON.parse(signUpUserData));
      navigation(`/register/verify-phone`);
    }
  };

  useEffect(() => {
    if (locationState) {
      setAlertMessage(locationState?.text);
    }
  }, [locationState]);

  const captchaResolved = isCaptchaEnabled() ? isCaptchaCaptured : true;

  if (!authContext.signUpUserData) {
    return null;
  }
  const {
    email,
    gender,
    zip,
    dob,
    phone,
    firstName,
    lastName,
    payPalEmail,
    giftCardEmail,
    venmoPhone,
    utmCode,
    organization,
    inviteCode,
  } = authContext.signUpUserData;

  const onComparePassword = (confirmPass: string) => {
    if (confirmPass === "") {
      setConfirmPassError("");
      return;
    }
    if (confirmPass !== passwordAdvance) {
      setConfirmPassError("The passwords you have entered don’t match. Please correct to proceed!");
    } else {
      setConfirmPassError("Passwords match");
    }
  };

  const onChangePassword = async (e: any) => {
    e.stopPropagation();
    if (!email && !passwordAdvance && codeIsValid) {
      setConfirmPass("");
      setPasswordAdvance("");
      return;
    }
    try {
      await authContext.forgotPassword(email, code, passwordAdvance);
      const successMessage = "Your password has been successfully reset! You can now sign in with your new password";
      navigation("/login", { state: { successMessage } });
    } catch (error: any) {
      if (error.name && error.name === "CodeMismatchException") {
        setIsOTPValid(true);
      }
      setGenericErrorMessage(error.message);
      setOpen(true);
    }
  };

  const onNextPressed = async (e: any) => {
    setIsPasswordValid(true);
    e.stopPropagation();
    const ref = searchParams.get("ref");

    if (
      firstName &&
      lastName &&
      email &&
      phone &&
      ((dob && zip && gender) || (organization && inviteCode)) &&
      passwordAdvance
    ) {
      try {
        const payload = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          dob,
          ...(zip && {
            address: {
              zip,
            },
          }),
          phone,
          role: inviteCode?.length ? UserRoles.RESEARCHER : UserRoles.USER,
          gender,
          ref,
          active: true,
          payPalEmail,
          venmoPhone,
          giftCardEmail,
          password: passwordAdvance,
          utmCode,
          organization,
          inviteCode,
          recaptchaToken,
        };
        const createdUser = await UsersService.registerCognitoUser(payload);
        if (authContext.setSignUpUserData) {
          authContext.setSignUpUserData((prevData) => ({
            ...prevData,
            passwordAdvance,
            id: createdUser.data._id,
          }));
        } else {
          throw new Error("Failed to set password in state");
        }
        await setItemIDB("isOTPScreenOn", "1");
        await setItemIDB(
          "signUpUserData",
          JSON.stringify({
            ...authContext.signUpUserData,
            passwordAdvance,
            id: createdUser.data._id,
          })
        );
        return navigation(`/register/verify-phone`, { state: { registrationDate: new Date() } });
      } catch (error: any) {
        if (error.response) {
          let { statusCode, message: messageObj, error: err } = error.response.data || {};
          let { message } = messageObj || {};

          statusCode ??= error.response.status;
          message ??= messageObj;
          err ??= messageObj?.error;

          if (err?.name === "UsernameExistsException") {
            setGenericErrorMessage("An account with this email address already exists.");
          } else if (statusCode === 500) {
            setGenericErrorMessage("The phone number must be from USA");
          } else {
            setGenericErrorMessage(message);
          }
        } else {
          setGenericErrorMessage(error.message);
        }
        captchaRef.current && captchaRef.current.reset();
        setOpen(true);
      }
    }
  };

  const verifyCaptcha = async (token: string | null) => {
    if (captchaRef.current) {
      token = captchaRef.current.getValue();
      try {
        if (token?.length) {
          setRecaptchaToken(token);
          setIsCaptchaCaptured(true);
        } else {
          captchaRef.current.reset();
          setIsCaptchaCaptured(false);
        }
      } catch (error) {
        captchaRef.current.reset();
        setIsCaptchaCaptured(false);
      }
    }
  };

  const onCaptchaExpired = () => {
    if (captchaRef.current) {
      captchaRef.current.reset();
    }
    setIsCaptchaCaptured(false);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onNextClick = async (e: any) => {
    if (type === PasswordType.RECOVERY) {
      await onChangePassword(e);
    } else {
      await onNextPressed(e);
    }
  };

  return (
    <div style={backgroundStyle}>
      <div style={containerStyle}>
        <Paper elevation={1} style={paperStyle}>
          <Grid style={{ ...logoStyle, justifyContent: "start" }}>
            <img src={verasightLogo} alt="Verasight Logo" style={{ width: "150px" }} />
          </Grid>
          <LinearProgress variant="determinate" value={40} style={{ color: "#2EBDEE" }} />
          {type === PasswordType.RECOVERY ? (
            <h1 style={h1Style}>Password recovery</h1>
          ) : (
            <h1 style={h1Style}>Set Password</h1>
          )}
          {type === PasswordType.RECOVERY ? (
            <>
              <p style={passwordRecoverySubtitleStyle}>Please enter your verification code</p>

              <OutlinedInput
                inputProps={{ autoComplete: "new-password" }}
                type="password"
                disabled={true}
                style={{ display: "none" }}
              />
              <OutlinedInput
                size="small"
                placeholder="Verification code"
                id="field1"
                inputProps={{
                  autoComplete: "new-password",
                }}
                error={isOTPValid}
                type="number"
                style={{ ...inputStyle, maxWidth: "400px" }}
                onKeyDown={(event) => disableKeyDown(event, ["ArrowDown", "ArrowUp"])}
                onChange={(e: any) => {
                  setIsOTPValid(false);
                  setCode(e.target.value);
                }}
              />
            </>
          ) : null}

          <p style={pleseEnterNewPasswordStyle}>Please enter a new password</p>
          <p style={passwordSubtitleStyle}>Password</p>
          <OutlinedInput
            size="small"
            placeholder="Password"
            name="password"
            type={showPassword.password ? "text" : "password"}
            style={{ ...inputStyle, maxWidth: "400px" }}
            error={!isPasswordValid}
            onChange={(e: any) => setPasswordAdvance(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("password")}
                  edge="end"
                >
                  {showPassword.password ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <p style={plabelStyle}>Confirm Password</p>
          <OutlinedInput
            size="small"
            placeholder="Confirm Password"
            type={showPassword.confirm ? "text" : "password"}
            style={{ ...inputStyle, maxWidth: "400px" }}
            error={!isConfirmPasswordValid}
            onChange={(e: any) => setConfirmPass(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("confirm")}
                  edge="end"
                >
                  {showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {isCaptchaEnabled() && (
            <ReCAPTCHA // Skip Google Captcha for tests. Only staging/development env.
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_ID ?? ""}
              ref={captchaRef}
              onChange={verifyCaptcha}
              onExpired={onCaptchaExpired}
              style={{ marginTop: "30px" }}
            />
          )}
          <Grid style={checkListStyle}>
            {passwordAdvanceIsValid
              ? // eslint-disable-next-line
                passwordAdvanceIsValid.map((item: any, index: number) => {
                  if (item.text) {
                    if (item.active) {
                      return (
                        <p key={index} style={{ ...checkListItemStyle, color: "#384144" }}>
                          <CheckIcon style={{ width: "20px", color: "#2ebdee" }} />
                          {item.text}
                        </p>
                      );
                    } else {
                      return (
                        <p key={index} style={checkListItemStyle}>
                          <CheckIcon style={{ width: "20px" }} />
                          {item.text}
                        </p>
                      );
                    }
                  }
                })
              : null}

            {confirmPassError ? (
              confirmPassError === "Passwords match" ? (
                <p key={"confirm-pass-error"} style={{ ...checkListItemStyle, color: "#384144" }}>
                  <CheckIcon style={{ width: "20px", color: "#2ebdee" }} />
                  {confirmPassError}
                </p>
              ) : (
                <p key={"confirm-pass-error"} style={checkListItemStyle}>
                  {confirmPassError}
                </p>
              )
            ) : null}
          </Grid>
          <div style={{ ...rowStyle, marginTop: "60px" }}>
            <div style={{ flex: "1 1 30%" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={{
                  ...btnStyle,
                  backgroundColor: "white",
                  color: "#5F6F74",
                }}
                fullWidth
                onClick={(e) => {
                  navigation(-1);
                }}
              >
                Back
              </Button>
            </div>
            <div style={{ flex: "1 1 60%" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={isPasswordValid && isConfirmPasswordValid && captchaResolved ? btnStyle : btnStyleDisabled}
                fullWidth
                onClick={onNextClick}
                disabled={!(isPasswordValid && isConfirmPasswordValid && captchaResolved)}
              >
                Next
              </Button>
            </div>
          </div>
        </Paper>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={genericErrorMessage}
          action={<SnackBarClose handleClose={handleClose} />}
        />

        {alertMessage ? (
          <div style={{ position: "absolute", width: "100%" }}>
            <Alert
              variant="filled"
              severity="success"
              onClose={() => setAlertMessage("")}
              sx={{ maxWidth: "925px", margin: "auto", mt: "25px" }}
            >
              {alertMessage}
            </Alert>
          </div>
        ) : null}
      </div>

      <SideCarousel />
    </div>
  );
};

export default CheckPassword;
