import React, { ChangeEventHandler } from "react";
import { settingKeys } from "src/types/Settings";
import { Alert, Box, Button, Divider, IconButton, InputAdornment, Paper, Stack, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import SettingsService from "../../../services/api/settingsService";

import "./SettingsTab.scss";

export const StyledButton = styled(Button)({
  textTransform: "capitalize",
  backgroundColor: "#2EBDEE",
  border: "1px solid #2EBDEE",
  borderRadius: "4px",
  padding: "5px 40px",

  "&:hover": {
    background: "#2EBDEE",
    opacity: "0.7",
  },

  "&:disabled": {
    color: "#91A3A9",
    backgroundColor: "#DCE2E4",
    border: "1px solid #DCE2E4",
  },
});

const alertMessages: any = {
  "point-conversion": "Point conversion updated!",
  "points-per-bonus": "Number of points needed updated!",
  "min-survey-for-bonus": "Number of surveys needed updated!",
};

const RewardPoints = () => {
  const [settings, setSettings] = React.useState<any>([]);
  const [pointConversion, setPointConversion] = React.useState<number>(10000);
  const [isConversionEditable, setIsConversionEditable] = React.useState<boolean>(false);
  const [surveysPerBonus, setSurveysPerBonus] = React.useState<number>(20);
  const [pointsPerBonus, setPointsPerBonus] = React.useState<number>(500);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");

  const [hitMultiplier, setHitMultiplier] = React.useState<number | null>(1);
  const [hitMultiplierProbability, setHitMultiplierProbability] = React.useState<number | null>(0);

  const [weeklyCashoutThreshold, setWeeklyCashoutThreshold] = React.useState<number>(0);

  React.useEffect(() => {
    SettingsService.getSettings().then((res) => {
      let data: any = {};
      // eslint-disable-next-line
      res.data.map(({ key, payload }: any) => {
        if (payload.value) data[key] = payload.value;
      });
      setSettings(data);
      if (data[settingKeys.POINT_CONVERSION]) {
        setPointConversion(data[settingKeys.POINT_CONVERSION]);
      }
      if (data[settingKeys.SURVEYS_PER_BONUS]) {
        setSurveysPerBonus(data[settingKeys.SURVEYS_PER_BONUS]);
      }
      if (data[settingKeys.POINTS_PER_BONUS]) {
        setPointsPerBonus(data[settingKeys.POINTS_PER_BONUS]);
      }
      if (data[settingKeys.HIT_MULTIPLIER]) {
        setHitMultiplier(data[settingKeys.HIT_MULTIPLIER]);
      }
      if (data[settingKeys.HIT_MULTIPLIER]) {
        setHitMultiplierProbability(data[settingKeys.HIT_MULTIPLIER]);
      }
    });
  }, []);

  const onChangePointConversion = (event: any) => {
    let value = Number(event.target.value);

    if (isNaN(value)) {
      setPointConversion(10000);
    } else if (value >= 0) {
      setPointConversion(value);
    }
  };

  const onChangeSurveyPerBonus = (event: any) => {
    let value = Number(event.target.value);

    if (isNaN(value)) {
      setSurveysPerBonus(20);
    } else if (value >= 0) {
      setSurveysPerBonus(value);
    }
  };

  const onChangePointsPerBonus = (event: any) => {
    let value = Number(event.target.value);

    if (isNaN(value)) {
      setPointsPerBonus(500);
    } else if (value >= 0) {
      setPointsPerBonus(value);
    }
  };

  const onChangeWeeklyCashoutTrehshold = (event: any) => {
    let value = Number(event.target.value);

    if (isNaN(value)) {
      setWeeklyCashoutThreshold(0);
    } else if (value >= 0) {
      setWeeklyCashoutThreshold(value);
    }
  };

  const onChangeHitMultiplier: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === "") {
      setHitMultiplier(null);
      return;
    }
    const multiplier = +e.target.value.replace(/[^0-9.]/g, "");
    setHitMultiplier(isNaN(multiplier) ? 1 : multiplier);
  };

  const onChangeHitMultiplierProbability: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === "") {
      setHitMultiplierProbability(null);
      return;
    }
    const probability = +e.target.value.replace(/[^0-9.]/g, "");
    setHitMultiplierProbability(isNaN(probability) ? 1 : probability);
  };

  const saveSetting = (key: settingKeys, value: number) => {
    if (!value) return;
    setLoading(true);
    const data = {
      key,
      payload: { value },
    };
    SettingsService.postSetting(data).then((res) => {
      if (key === settingKeys.POINT_CONVERSION) setIsConversionEditable(false);
      let data = settings;
      data[key] = value;
      setSettings(data);
      setLoading(false);
      setAlertMessage(alertMessages[key]);
    });
  };

  return (
    <Paper
      sx={{
        flexGrow: 1,
        marginY: "20px",
        padding: "70px",
        boxShadow: "3px 24px 20px rgba(0, 0, 0, 0.06)",
        borderRadius: "16px",
      }}
    >
      {alertMessage ? (
        <Stack sx={{ width: "100%", margin: "0 0 20px" }}>
          <Alert variant="filled" severity="success" onClose={() => setAlertMessage("")}>
            {alertMessage}
          </Alert>
        </Stack>
      ) : null}

      <p className="subtitle">Point Conversion</p>
      <Box className="point-conversion-row">
        <span>1$ = </span>
        <TextField
          variant="outlined"
          disabled={!isConversionEditable}
          size="small"
          value={!isConversionEditable ? pointConversion + " Points" : pointConversion}
          type={!isConversionEditable ? "text" : "number"}
          onChange={onChangePointConversion}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsConversionEditable(true)}>
                  <BorderColorIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <StyledButton
          variant="contained"
          disabled={!isConversionEditable || loading}
          onClick={() => saveSetting(settingKeys.POINT_CONVERSION, pointConversion)}
        >
          Confirm Change
        </StyledButton>
      </Box>

      <Divider sx={{ margin: "50px 0 40px", border: "1px solid #dce2e4" }} />

      <div>
        <p className="subtitle">Weekly Cashout Threshold</p>
        <Box className="bonus-points-row">
          <TextField
            variant="outlined"
            size="small"
            type={"number"}
            value={weeklyCashoutThreshold}
            onChange={onChangeWeeklyCashoutTrehshold}
          />
          <StyledButton
            variant="contained"
            disabled={settings[settingKeys.WEEKLY_CAHSOUT_THRESHOLD] === weeklyCashoutThreshold || loading}
            onClick={() => saveSetting(settingKeys.WEEKLY_CAHSOUT_THRESHOLD, weeklyCashoutThreshold)}
          >
            Confirm Change
          </StyledButton>
        </Box>
      </div>

      <Divider sx={{ margin: "50px 0 40px", border: "1px solid #dce2e4" }} />

      <p className="subtitle"> Bonus Points</p>
      <span className="info-label">Number of surveys needed to activate bonus points</span>
      <Box className="bonus-points-row">
        <TextField
          variant="outlined"
          size="small"
          type={"number"}
          value={surveysPerBonus}
          onChange={onChangeSurveyPerBonus}
        />
        <StyledButton
          variant="contained"
          disabled={settings[settingKeys.SURVEYS_PER_BONUS] === surveysPerBonus || loading}
          onClick={() => saveSetting(settingKeys.SURVEYS_PER_BONUS, surveysPerBonus)}
        >
          Confirm Change
        </StyledButton>
      </Box>

      <span className="info-label">How many points per Bonus</span>
      <Box className="bonus-points-row">
        <TextField
          variant="outlined"
          size="small"
          type={"number"}
          value={pointsPerBonus}
          onChange={onChangePointsPerBonus}
        />
        <StyledButton
          variant="contained"
          disabled={settings[settingKeys.POINTS_PER_BONUS] === pointsPerBonus || loading}
          onClick={() => saveSetting(settingKeys.POINTS_PER_BONUS, pointsPerBonus)}
        >
          Confirm Change
        </StyledButton>
      </Box>

      <Divider sx={{ margin: "50px 0 40px", border: "1px solid #dce2e4" }} />

      <p className="subtitle">Hit Multiplier</p>
      <span className="info-label">Multiplier for the randomly allocated bonus multiplier</span>
      <span className="info-label">(greater than or equal to 1)</span>
      <Box className="bonus-points-row">
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={hitMultiplier}
          onChange={onChangeHitMultiplier}
        />
        <StyledButton
          variant="contained"
          onClick={() => saveSetting(settingKeys.HIT_MULTIPLIER, hitMultiplier ?? 1)}
          disabled={hitMultiplier == null || settings[settingKeys.HIT_MULTIPLIER] === hitMultiplier || loading}
        >
          Confirm Change
        </StyledButton>
      </Box>

      <span className="info-label">Probability of the randomly allocated bonus</span>
      <span className="info-label">(between 0 and 1)</span>
      <Box className="bonus-points-row">
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={hitMultiplierProbability}
          onChange={onChangeHitMultiplierProbability}
        />
        <StyledButton
          variant="contained"
          onClick={() => saveSetting(settingKeys.HIT_MULTIPLIER_PROBABILITY, hitMultiplierProbability ?? 0)}
          disabled={
            hitMultiplierProbability == null ||
            settings[settingKeys.HIT_MULTIPLIER_PROBABILITY] === hitMultiplierProbability ||
            loading
          }
        >
          Confirm Change
        </StyledButton>
      </Box>
    </Paper>
  );
};

export default RewardPoints;
