import { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, TextField, Tooltip, TooltipProps, tooltipClasses, Typography } from "@mui/material";
import { type SurveyPrice as SurveyPriceType } from "src/types/Payment";
import PaymentsService from "src/services/api/paymentsService";
import { useSnackbar } from "notistack";
import { SurveyPriceLookUpKeys, matrixQuestionMultiplier } from "src/constants/prices";
import OverlayLoader from "src/components/Loader/OverLayLoader";
import InfoLabel from "./InfoLabel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { SurveyLimits } from "src/types/Survey";
import { getQuestionCount } from "src/helpers/surveyHelper";
import InvitationsService from "src/services/api/invitationsService";
import { getErrorMessage } from "src/helpers/errors";
import "./SurveyCost.scss";
import { format2Currency } from "src/helpers/currency";
import InfoIcon from "@mui/icons-material/Info";
import styled from "@emotion/styled";

const maxQuestions = 50;

interface Props {
  readOnly?: boolean;
  limits?: SurveyLimits;
  setLimits?: (limits: SurveyLimits) => void;
  children?: React.ReactNode;
}

const iconStyle = {
  position: "absolute",
  color: "blue",
  paddingLeft: "3px",
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "1rem", // Change the font size here
    // padding: "10px",  // Optional: change padding
    borderRadius: "4px", // Optional: change border radius
    width: "500px",
  },
}));

const SurveyCost = ({ setLimits, readOnly, limits, children }: Props) => {
  const [prices, setPrices] = useState<Array<SurveyPriceType>>([]);
  const [sampleSize, setSampleSize] = useState<number>(limits?.sampleSize ?? 0);
  const [multipleChoice, setMultipleChoice] = useState<number>(limits?.multipleChoiceQuestions ?? 0);
  const [matrix, setMatrix] = useState<number>(limits?.matrixQuestions ?? 0);
  const [openEnded, setOpenEnded] = useState<number>(limits?.openEndedQuestions ?? 0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [oldDiscountCode, setOldDiscountCode] = useState<string | undefined>();
  const [discountCode, setDiscountCode] = useState<string | undefined>(limits?.discountCode);
  const [discountPercent, setDiscountPercent] = useState<number | undefined>(limits?.discount);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setIsLoading(true);
    PaymentsService.getPrices()
      .then((res) => {
        setPrices(res.data.docs ?? []);
      })
      .catch((err) => {
        enqueueSnackbar(`failed to get the prices. ${getErrorMessage(err)}`, { variant: "error" });
      })
      .finally(() => setIsLoading(false));
    if (!readOnly && !discountCode) {
      InvitationsService.getAppliedInviteCode()
        .then((response) => {
          if (response.data && !response.data?.discountUsedBy && response.data.discount > 0) {
            setDiscountCode(response.data?.shortCode);
            setDiscountPercent(response.data?.discount);
          }
        })
        .catch((err) => {
          enqueueSnackbar(`failed to get your applicable discount code. ${getErrorMessage(err)}`, { variant: "error" });
        });
    }
  }, []);

  useEffect(() => {
    if (typeof setLimits !== "function") {
      return;
    }
    setLimits({
      sampleSize,
      multipleChoiceQuestions: multipleChoice,
      matrixQuestions: matrix,
      openEndedQuestions: openEnded,
      ...(discountPercent && { discountCode }),
    });
  }, [multipleChoice, matrix, openEnded, sampleSize, discountCode, discountPercent]);

  const onchangeDiscountCode = async (discountCode?: string, oldDiscountCode?: string) => {
    if (readOnly) return;
    if (!discountCode) {
      setDiscountPercent(0);
      return;
    }
    if (oldDiscountCode === discountCode) {
      return;
    }
    if (discountCode.length < 6 || discountCode.length > 12) {
      setDiscountPercent(0);
      enqueueSnackbar("Invalid discount code", { variant: "warning" });
      return;
    }
    try {
      setIsLoading(true);
      const invite = await InvitationsService.validateDiscount(discountCode);
      setDiscountPercent(invite.data.discount);
      closeSnackbar();
    } catch (err) {
      setDiscountPercent(0);
      enqueueSnackbar(`failed to validate discount code. ${getErrorMessage(err as Error)}`, { variant: "error" });
    } finally {
      setOldDiscountCode(discountCode);
      setIsLoading(false);
    }
  };

  const getPriceByKey = (key: SurveyPriceLookUpKeys): number => {
    const { unitAmount } = prices.find((price) => price.lookupKey === key) || {};
    if (!unitAmount) return 0;
    return unitAmount / 100;
  };

  const handleQuestionChange = (limit: number, val: number, oldVal: number, setter: (val: number) => void) => {
    if (val <= limit && val >= 0) {
      setter(val);
    } else {
      setter(oldVal);
    }
  };
  const formattedNumber = (val: number): number | string => (val > 0 ? val : "");

  const totalQuestions = getQuestionCount({
    matrixQuestions: matrix,
    multipleChoiceQuestions: multipleChoice,
    openEndedQuestions: openEnded,
    sampleSize,
  });

  const getPricePerRespondent = () => {
    let pricePerRespondent = 0;
    if (totalQuestions === 0) return 0;
    if (totalQuestions < 20) {
      pricePerRespondent = getPriceByKey(SurveyPriceLookUpKeys.upto20Questions);
    } else if (totalQuestions < 40) {
      pricePerRespondent = getPriceByKey(SurveyPriceLookUpKeys.upto40Questions);
    } else if (totalQuestions <= 50) {
      pricePerRespondent = getPriceByKey(SurveyPriceLookUpKeys.upto50Questions);
    }
    return pricePerRespondent;
  };

  const getTotal = (): number => {
    const pricePerRespondent = getPricePerRespondent();
    const totalCost = sampleSize * pricePerRespondent;
    return +totalCost.toFixed(2);
  };

  const getDiscountedTotal = (): number => {
    if (!discountPercent) return getTotal();

    //to match with stripe price_data calculation in backend
    //we need to send per unit price to stripe, so discounted amount sometimes is a few cents off otherwise
    const unitPricePerRespondent = getPricePerRespondent() * 100;
    const discountedUnitPrice = parseFloat((unitPricePerRespondent * ((100 - discountPercent) / 100)).toFixed(2));

    return (Math.round(discountedUnitPrice) / 100) * sampleSize;
  };

  const maxMultipleChoice = maxQuestions - Math.ceil(matrix * matrixQuestionMultiplier) - openEnded;
  const maxMatrix = Math.min(150, Math.floor((maxQuestions - multipleChoice - openEnded) / matrixQuestionMultiplier));
  const maxOpenEnded = Math.min(2, maxQuestions - multipleChoice - Math.ceil(matrix * matrixQuestionMultiplier));

  const headerLabel = readOnly ? "Study Cost Breakdown" : "Study Cost Calculator";

  return (
    <Paper
      sx={{
        position: "relative",
        p: 4,
        minWidth: 750,
      }}
      elevation={3}
    >
      {isLoading ? <OverlayLoader /> : null}
      <Typography variant="body1" fontWeight="bold" gutterBottom>
        {headerLabel}
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Verasight’s self-serve portal supports nationally-representative surveys up-to 50 questions and 2000
        respondents. For custom audiences, a larger sample size, or a longitudinal study, please contact us here.
      </Typography>
      <TextField
        disabled={readOnly}
        sx={{ width: 200 }}
        data-testid="txt-sample-size"
        label="Sample Size (100-2000)"
        value={sampleSize > 0 ? sampleSize : ""}
        placeholder="100 - 2000"
        onChange={(e) => {
          const val = +e.target.value;
          if (val <= 2000) setSampleSize(val);
        }}
        margin="normal"
      />

      <Box display="flex" alignItems="top" mt={2} mb={2}>
        <Typography variant="body1" fontWeight="bold">
          {`Questions `}
        </Typography>
        <Typography sx={{ flex: 2, paddingLeft: "5px" }} variant="subtitle2" gutterBottom>
          {`(Up to 50 total questions, matrix items count for ${matrixQuestionMultiplier} question value, and the total combined questions
          value can be 50 across, multiple-choice, matrix and open-ended questions)`}
        </Typography>
      </Box>

      <Box display="flex" sx={{ gap: 2 }}>
        <Box flex={1}>
          <Typography mt={2}>
            Multiple-choice Questions
            <CustomTooltip title="A question that provides a single question stem and a list of possible answers. Can be single-or multi-select answers.">
              <InfoIcon sx={iconStyle} />
            </CustomTooltip>
            <br />
            (max {maxMultipleChoice})
          </Typography>
        </Box>
        <Box flex={2}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-testid="txt-multi-question"
            placeholder={`0 - ${maxMultipleChoice}`}
            value={formattedNumber(multipleChoice)}
            onChange={(e) =>
              handleQuestionChange(maxMultipleChoice, +e.target.value, multipleChoice, setMultipleChoice)
            }
            type="number"
            margin="normal"
          />
        </Box>
        <InfoLabel val={multipleChoice || 0} label="Total Multiple-choice" testId="lbl-multi-total" />
      </Box>

      <Box display="flex" sx={{ gap: 2 }}>
        <Box flex={1}>
          <Typography mt={2}>
            Matrix Items
            <CustomTooltip title="A set of questions or statements that are displayed in a grid, with a shared response scale.">
              <InfoIcon sx={iconStyle} />
            </CustomTooltip>
            <br />
            (max {maxMatrix})
          </Typography>
        </Box>
        <Box flex={2}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-testid="txt-matrix-question"
            placeholder={`0 - ${maxMatrix}`}
            value={formattedNumber(matrix)}
            onChange={(e) => handleQuestionChange(maxMatrix, +e.target.value, matrix, setMatrix)}
            type="number"
            margin="normal"
          />
        </Box>
        <InfoLabel val={matrix || 0} label="Total Matrix" testId="lbl-matrix-total" />
      </Box>

      <Box sx={{ gap: 2 }} display="flex">
        <Box flex={1}>
          <Typography mt={2}>
            Open-ended Questions
            <CustomTooltip title="A question that requires the respondent to type word(s), a phrase, or sentence(s) into a free-entry text box.">
              <InfoIcon sx={iconStyle} />
            </CustomTooltip>
            <br />
            (max {maxOpenEnded})
          </Typography>
        </Box>
        <Box flex={2}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-testid="txt-open-question"
            placeholder={`0 - ${maxOpenEnded}`}
            value={formattedNumber(openEnded)}
            onChange={(e) => handleQuestionChange(maxOpenEnded, +e.target.value, openEnded, setOpenEnded)}
            type="number"
            margin="normal"
          />
        </Box>
        <InfoLabel val={openEnded || 0} label="Total Open-ended" testId="lbl-open-total" />
      </Box>

      <Box display="flex" mb={2} sx={{ gap: 2 }}>
        <Box flex={1}></Box>
        <Box flex={2}></Box>
        <InfoLabel pt={0} val={totalQuestions} label="Total Questions" testId="lbl-total-question" />
      </Box>

      <Box display="flex" sx={{ gap: 2 }} mb={2}>
        <Box flex={1}></Box>
        <Box flex={2}></Box>
        <InfoLabel pt={0} val={"$" + (format2Currency(getTotal()) ?? "0.00")} label="Total" testId="lbl-total" />
      </Box>

      <Box sx={{ gap: 2 }} display="flex">
        <Box flex={1}>
          <Typography mt={5}>Discount Code</Typography>
        </Box>
        <Box flex={2}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={readOnly ? 12 : 10}>
              <Box display="flex" alignItems="stretch">
                <TextField
                  disabled={readOnly}
                  fullWidth
                  data-testid="txt-discount-code"
                  placeholder={readOnly ? "" : "ABC123"}
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                  margin="normal"
                  InputProps={{
                    className: discountPercent && discountPercent > 0 ? "discountApplied" : "",
                    endAdornment:
                      discountPercent && discountPercent > 0 ? (
                        <div className="icon">
                          <CheckCircleOutlineIcon fontSize="small" />
                        </div>
                      ) : null,
                  }}
                />
              </Box>
            </Grid>
            {!readOnly ? (
              <Grid item xs={2}>
                <Box display="flex" alignItems="stretch" height="100%">
                  <Button
                    data-testid="btn-discount-apply"
                    fullWidth
                    variant="contained"
                    disabled={!discountCode || discountCode.length < 6 || oldDiscountCode === discountCode}
                    onClick={() => onchangeDiscountCode(discountCode, oldDiscountCode)}
                  >
                    Apply
                  </Button>
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Box>

        <InfoLabel val={`${discountPercent ?? 0}%`} label="Discount" testId="lbl-discount" />
      </Box>

      <Box display="flex" sx={{ gap: 2 }} mb={2} mt={5}>
        <Box flex={3}>
          {" "}
          <InfoLabel
            pt={0}
            val={"$" + (format2Currency(getPricePerRespondent()) ?? "0.00")}
            label="Price per Respondent"
            testId="lbl-price-respondent"
          />
        </Box>
        <InfoLabel
          val={"$" + (format2Currency(getDiscountedTotal()) ?? "0.00")}
          label="Total Cost"
          testId="lbl-total-cost"
        />
      </Box>

      {children}
    </Paper>
  );
};

export default SurveyCost;
