import { Box, Container } from "@mui/material";
import "./SelfServeGuide.scss";
import image1 from "../../../assets/image5.png";
import image2 from "../../../assets/image2.png";
import image3 from "../../../assets/image4.png";
import image4 from "../../../assets/image3.png";
import image5 from "../../../assets/image7.png";
import image6 from "../../../assets/image6.png";
import image7 from "../../../assets/image1.png";

export default function SelfServeGuide() {
  return (
    <Container>
      <Box sx={{ textAlign: "center" }}>
        <h1>Verasight's Self-Serve Survey Integration Guide</h1>
      </Box>
      <Box sx={{ marginBottom: "4rem" }}>
        <Box>
          <h2>General set-up instructions for surveys programmed in any platform</h2>

          <p>
            After programming your survey in your preferred survey software, there are 3 steps to setting up your survey
            to use with Verasight's self-serve platform:
          </p>

          <ol>
            <li>Directing respondents into your survey</li>
            <li>Redirecting respondents back to Verasight</li>
            <li>Testing your redirects</li>
          </ol>
        </Box>
        <Box>
          <h3>1. Directing respondents into your survey</h3>

          <div className="depth-1">
            <p>1. In your Verasight account, click the hamburger menu on the survey you would like to launch</p>

            <img src={image1} alt="Available Surveys" />

            <div className="depth-2">
              <p>a. Click “Edit”</p>
              <p>b. Copy and paste the distribution link survey here:</p>
              <img src={image2} alt="Set Up Your Study" />
              <p>c. Click the blue “Save Information” button</p>
            </div>
          </div>
          <div className="depth-1">
            <p>
              2. Open your programmed survey. In this step, we will ensure that your survey captures the elements
              necessary to record the survey and respondent IDs so that Verasight respondents are compensated when they
              submit your survey. This means that we need to know which survey they took (with the URL parameter{" "}
              <b>surveyid</b>) and which panelist took the survey (with the URL parameter <b>vsid</b>). These elements
              will be passed into your survey from Verasight automatically. Ensure that you are capturing these
              variables:
            </p>

            <div className="depth-2">
              <p>a. The variable names that will be appended to your inbound URL are:</p>
              <div className="depth-3">
                <p>i. vsid</p>
                <p>ii. surveyid</p>
                <p>
                  iii. As an example, your link to www.survey.com would look like this:
                  www.survey.com?surveyid=123&amp;vsid=user123 with “surveyid” and “vsid” automatically appended to your
                  survey URL when respondents begin your survey in their Verasight account
                </p>
              </div>
              <p>
                b. The way that you capture these URL parameters will depend on the survey software that you are using.
                Here are links to support for common survey platforms:
              </p>
              <div className="depth-3">
                <p>
                  i. <a href="https://help.redcap.ualberta.ca/help-and-faq/survey-parameters">REDCAP</a>
                </p>
                <p>
                  ii.{" "}
                  <a href="https://www.typeform.com/help/a/dynamically-pass-hidden-fields-from-a-url-to-an-embedded-form-4404652303892/">
                    Typeform
                  </a>
                </p>
                <p>
                  iii. <a href="https://help.surveymonkey.com/en/surveymonkey/send/custom-variables/">SurveyMonkey</a>
                </p>
                <p>
                  iv.{" "}
                  <a href="https://forstasurveys.zendesk.com/hc/en-us/articles/4409469904411-Capturing-the-Referer-URL">
                    Forsta
                  </a>
                </p>
                <p>v. Using another platform? Reach out to our team with any questions.</p>
              </div>
            </div>
          </div>
        </Box>
        <Box>
          <h3>2. Redirecting respondents back to Verasight on survey completion</h3>

          <div className="depth-1">
            <p>
              1. Identify the termination points in your survey. We need to direct respondents back to the survey at 2
              termination points:
            </p>
            <div className="depth-2">
              <p>
                a. Those who do <u>not</u> consent should be terminated
              </p>
              <div className="depth-3">
                <p>i. Insert the termination link from your Verasight account page</p>
                <img src={image3} alt="Set Up Your Study" />
                <p>
                  ii. <b>Important:</b> ensure that you replace {"{USER_ID}"} in the URLs with the appropriate variable
                  that passes back in the <b>vsid</b>
                </p>
              </div>
              <p>
                b. Those who <u>do</u> consent should be redirected back to Verasight upon completing your survey
              </p>
              <div className="depth-3">
                <p>i. Insert the complete link from your Verasight account page</p>
                <img src={image4} alt="Set Up Your Study" />
                <p>
                  ii. <b>Important:</b> ensure that you replace {"{USER_ID}"} in the URLs with the appropriate variable
                  that passes back in the <b>vsid</b>
                </p>
              </div>
            </div>
          </div>
        </Box>
        <Box>
          <h3>3. Testing your link</h3>
          <p>Update when ready</p>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "4rem" }}>
        <h2>Set-up instructions for surveys programmed in Qualtrics</h2>

        <h3>1. Directing respondents into your survey</h3>

        <div className="depth-1">
          <p>3. In your Verasight account, click the hamburger menu on the survey you would like to launch</p>
          <img src={image1} alt="Available Surveys" />
          <div className="depth-2">
            <p>a. Click “Edit”</p>
            <p>b. Copy and paste the Anonymous Distribution Link from your Qualtrics survey here:</p>
            <img src={image2} alt="Set Up Your Study" />
            <p>c. Click the blue “Save Information” button</p>
          </div>
        </div>
        <div className="depth-1">
          <p>
            4. Navigate to your Qualtrics survey. Ensure that you are recording the necessary inbound variables by
            adding the following embedded data elements in the Survey Flow of your Qualtrics survey
          </p>
          <div className="depth-2">
            <p>a. At the very beginning of your Survey Flow, add an embedded data element with the following:</p>
            <div className="depth-3">
              <p>
                i. <b>vsid</b>
              </p>
              <p>
                i. <b>surveyid</b>
              </p>
              <img src={image5} alt="Survey Flow" />
            </div>
          </div>
        </div>

        <h3>2. Redirecting respondents back to Verasight on survey completion</h3>

        <div className="depth-1">
          <p>
            2. Think about termination points in your survey. We need to direct respondents back to the survey at 2
            termination points:
          </p>
          <div className="depth-2">
            <p>
              a. Those who do <u>not</u> consent should be terminated
            </p>
            <div className="depth-3">
              <p>
                i. In order to pass these respondents back to Verasight with a “did not consent” indicator, add a Branch
                below your Consent block.
              </p>
              <p>ii. Finally, add an “End of Survey” element</p>
              <img src={image6} alt="End of Survey" />
              <p>
                iii. Click “Customize” in the End of Survey Element. Then click “Override Survey Options.” Finally,
                click “Redirect to a URL…”
              </p>
              <img src={image7} alt="Customize End of Survey" />
              <p>iv. Insert the termination link from your Verasight account page</p>
              <img src={image3} alt="Set Up Your Study" />
              <p>v. NEED TO UPDATE WITH QUALTRICS SPECIFIC LINK</p>
            </div>
            <p>
              b. Those who <u>do</u> consent should be redirected back to Verasight at the end of your survey flow
            </p>
            <div className="depth-3">
              <p>
                i. In order to pass these respondents back to Verasight with a “complete” indicator, add an “End of
                Survey” element at the very end of your survey
              </p>
              <p>
                ii. Click “Customize” in the End of Survey Element. Then click “Override Survey Options.” Finally, click
                “Redirect to a URL…”
              </p>
              <img src={image7} alt="Customize End of Survey" />
              <p>iii. Insert the complete link from your Verasight account page</p>
              <img src={image4} alt="Set Up Your Study" />
              <p>iv. NEED TO UPDATE WITH QUALTRICS SPECIFIC LINK</p>
            </div>
          </div>
        </div>

        <h3>3. Testing your link</h3>

        <p>Update when ready</p>
      </Box>
    </Container>
  );
}
