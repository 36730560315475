import { matrixQuestionMultiplier } from "src/constants/prices";
import { PointsTableData, RedirectPointsData, SurveyLimits, SurveyType } from "src/types/Survey";

export const getRedirectLinkPoints = (externalSurveyRedirectPointsData: RedirectPointsData) => {
  const redirectLinkPoints = externalSurveyRedirectPointsData.parameters
    ? externalSurveyRedirectPointsData.parameters.map((linkData: PointsTableData) => {
        return parseInt(linkData.parameterName ?? "0");
      })
    : [0];
  return redirectLinkPoints;
};

export const getQuestionCount = (limits: SurveyLimits): number => {
  return Math.ceil(
    limits.matrixQuestions * matrixQuestionMultiplier + limits.multipleChoiceQuestions + limits.openEndedQuestions
  );
};

export const isSurvey2CreateValid = (survey: Partial<SurveyType>): boolean => {
  if (!survey.title) {
    return false;
  }
  if (survey.limits) {
    const count = getQuestionCount(survey.limits);
    if (count < 1 || count > 50 || survey.limits.sampleSize < 1) return false;
  }
  return true;
};
