import React from "react";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VeraButton from "../../../../components/VeraButton";
import InformativeDialog from "./InformativeDialog";
import { checkIfThereAreInvalidEmails } from "../../../../helpers/generic";

const UserBulkUpdateDialog = (props: any) => {
  const { onClose, onSubmit, setCSVFileParsed, CSVFileParsed, open, userBulkUpdateTitle } = props;
  const [openInformativeDialog, setOpenInformativeDialog] = React.useState<string | false>();
  const [index, setIndex] = React.useState<number | false>();
  const [email, setEmail] = React.useState<string | false>();

  const handleClose = () => {
    onClose();
  };

  const removeRow = () => {
    if (typeof index === "number") {
      let newCSVileParsed = [...CSVFileParsed];
      newCSVileParsed.splice(index, 1);
      setCSVFileParsed(newCSVileParsed);
      if (newCSVileParsed.length === 0) {
        handleClose();
      }
      setIndex(false);
      setEmail(false);
      setOpenInformativeDialog(false);
    }
  };
  const handleOpenInformativeDialog = (index: number, email: string) => {
    setOpenInformativeDialog("removeRow");
    setIndex(index);
    setEmail(email);
  };

  const handleCloseInformativeDialog = () => {
    setOpenInformativeDialog(false);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: { borderRadius: 28, maxWidth: "1100px" },
        }}
      >
        <div className="dialog-content" style={{ width: "950px" }}>
          <p className="dialog-title">{userBulkUpdateTitle === "Tags" ? "Add or Remove": "Add"}  {userBulkUpdateTitle}</p>
          {CSVFileParsed && (
            <Table>
              <TableBody style={{ borderRadius: "16px" }}>
                {CSVFileParsed.map((user: any, index: number) => {
                  if (user.email.length <= 0) return null;
                  const isDelete = String(user.delete) === "1" || String(user.delete).toLowerCase() === "true";
                  return (
                    <TableRow key={index+user.tag} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell component="th" scope="row">
                        <b>{user.email}</b>
                      </TableCell>
                      <TableCell sx={isDelete ? { textDecoration: "line-through" } : {}}>
                          {userBulkUpdateTitle === "Tags" ? user.tag : user.points}
                        </TableCell>
                      <TableCell className={`status-cell ${user.valid ? "active" : "inactive"}`}>
                        {user.valid ? "Valid" : "Invalid"}
                      </TableCell>
                      <TableCell className="actions-button">
                        <IconButton onClick={(event: any) => handleOpenInformativeDialog(index, user.email)}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
          <div className="buttons-wrapper">
            <VeraButton variant="text" onClick={onClose} sx={{ border: "none" }}>
              Cancel
            </VeraButton>
            <VeraButton
              variant="outlined"
              onClick={() => onSubmit()}
              disabled={CSVFileParsed.length === 0 || checkIfThereAreInvalidEmails(CSVFileParsed)}
              className="dialog-upload-button"
            >
              Submit
            </VeraButton>
          </div>
        </div>
      </Dialog>
      <InformativeDialog
        onClose={handleCloseInformativeDialog}
        userId={""}
        userName={email}
        onAction={removeRow}
        open={openInformativeDialog}
      />
    </>
  );
};

export default UserBulkUpdateDialog;
