import React, { ChangeEventHandler, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { SurveyType, RedirectLinksData, SourceData, RedirectPointsData } from "src/types/Survey";
import VeraLink from "../../../components/VeraLink";
import SurveyCard from "../../../components/SurveyCard/SurveyCard";
import SurveysService from "../../../services/api/surveysService";

import "../EditSurvey/EditSurvey.scss";
import "./NewSurvey.scss";

import LeftColumnFields from "../EditSurvey/components/LeftColumnFields";
import SourceTab from "./components/SourceTab";
import RedirectLinksTab from "./components/RedirectLinksTab";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getRedirectLinkPoints } from "../../../helpers/surveyHelper";
import { v4 as uuidv4 } from "uuid";
import PaperDefault from "../../../components/common/PaperDefault";

type SurveyParams = {
  externalId: string;
  active?: boolean;
  title?: string;
  image?: any;
  start?: string | null;
  end?: string | null;
  category?: string | null;
  tags?: string[];
  targetingTags?: string[];
  points?: number | null;
  spinnerProbability?: number | null;
  enableSpinner?: boolean | null;
  externalSurveySourceData: SourceData | null;
  externalSurveyRedirectLinksData: RedirectLinksData | null;
  externalSurveyRedirectPointsData: RedirectPointsData | null;
  isLucidSurvey: boolean;
  multiPointPrefixDisabled?: boolean;
};

const NewSurvey = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const [status, setStatus] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>("");
  const [startDate, setStartDate] = React.useState<string | null>(null);
  const [endDate, setEndDate] = React.useState<string | null>(null);
  const [category, setCategory] = React.useState<string | null>(null);
  const [tags, setTags] = React.useState<string[]>([]);
  const [points, setPoints] = React.useState<number | null>(0);
  const [upToPointAmount, setUpToPointAmount] = React.useState<number | null>(0);
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const [image, setImage] = React.useState<string | null>(null);
  const [enableSpinner, setEnableSpinner] = React.useState(false);
  const [spinnerPercentage, setSpinnerPercentage] = React.useState<number | null>(0);
  const [isSourceView, setIsSourceView] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLucidSurvey, setIsLucidSurvey] = React.useState<boolean>(false);
  const [multiPointPrefixDisabled, setMultiPointPrefixDisabled] = React.useState<boolean>(true);
  const [originalSurvey, setOriginalSurvey] = React.useState<SurveyType>();
  const [surveyReady, setSurveyReady] = React.useState<boolean>();

  const navigate = useNavigate();

  const [redirectLinksData, setRedirectLinksData] = React.useState<RedirectLinksData>({
    baseUrl: "",
    parameters: [],
  });

  const [sourceData, setSourceData] = React.useState<SourceData>({
    baseUrl: "",
    parameters: [],
  });

  const [redirectPointsData, setRedirectPointsData] = React.useState<RedirectPointsData>({
    parameters: [],
  });

  React.useEffect(() => {
    const redirectLinkPoints = getRedirectLinkPoints(redirectPointsData);
    setUpToPointAmount(Math.max(...redirectLinkPoints));
  }, [points, redirectPointsData]);

  const onChangeSpinnerPercentage: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === "") {
      setSpinnerPercentage(null);
      return;
    }
    const probability = +e.target.value.replace(/[^0-9.]/g, "");
    if (0 <= probability && probability <= 100) {
      setSpinnerPercentage(probability);
    } else {
      setSpinnerPercentage(100);
    }
  };

  const saveChanges = async () => {
    await createSurvey(status);
  };

  const createSurvey = async (status: boolean) => {
    setIsLoading(true);
    let params: SurveyParams = {
      active: status,
      title,
      start: startDate,
      end: endDate,
      category,
      tags,
      points,
      enableSpinner,
      externalId: uuidv4(),
      externalSurveySourceData: sourceData,
      externalSurveyRedirectLinksData: redirectLinksData,
      externalSurveyRedirectPointsData: redirectPointsData,
      isLucidSurvey: isLucidSurvey,
      multiPointPrefixDisabled,
    };

    if (!title) {
      enqueueSnackbar(`You must fill in the required fields: title`, {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }

    if (spinnerPercentage != null) {
      params.spinnerProbability = spinnerPercentage / 100;
    }

    const sourceParamTypes = sourceData.parameters
      .filter((param) => {
        return ["SURVEY_ID", "USER_ID"].includes(param.parameterType.toUpperCase());
      })
      .map((param) => param.parameterType);
    // there needs to be atleast one SURVEY_ID and USER_ID parameter in the source tab or else
    // redirect links will not work
    if (!(sourceParamTypes.includes("SURVEY_ID") && sourceParamTypes.includes("USER_ID"))) {
      enqueueSnackbar("You must include both a Survey and User ID entry in the Source parameter table.", {
        variant: "error",
      });
      setIsLoading(false);
      setTimeout(() => {
        closeSnackbar();
      }, 5000);
      return;
    }

    try {
      const survey = await SurveysService.createSurvey(params);
      const surveyId = survey.data._id;

      if (selectedFile) {
        if (selectedFile.size > 1000000) {
          enqueueSnackbar("The selected image is above 1MB", { variant: "error" });
          return;
        }
        let formData = new FormData();
        formData.append("image", selectedFile);
        await SurveysService.updateSurveyImage(surveyId, formData);
      }

      enqueueSnackbar("Survey has been created.", { variant: "success" });
      navigate(`/admin/surveys/${surveyId}`);
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message, { variant: "error" });
    }

    setIsLoading(false);
    setTimeout(() => {
      closeSnackbar();
    }, 5000);
  };

  useEffect(() => {
    if (originalSurvey?._id) {
      if (originalSurvey.externalSurveySourceData?.baseUrl) {
        setSourceData(originalSurvey.externalSurveySourceData);
      }
      if (!isNaN(+originalSurvey.points)) {
        setPoints(originalSurvey.points);
      }
      if (originalSurvey.externalSurveyRedirectLinksData?.baseUrl) {
        setRedirectLinksData(originalSurvey.externalSurveyRedirectLinksData);
      }
      if (originalSurvey.externalSurveyRedirectLinksData?.baseUrl) {
        setRedirectLinksData(originalSurvey.externalSurveyRedirectLinksData);
      }
      if (originalSurvey.externalSurveyRedirectPointsData?.parameters?.length) {
        setRedirectPointsData(originalSurvey.externalSurveyRedirectPointsData);
      }
      if (!isNaN(+originalSurvey.spinnerProbability)) {
        setSpinnerPercentage(originalSurvey.spinnerProbability * 100);
      }
      setEnableSpinner(originalSurvey.enableSpinner);

      if (originalSurvey.image?.length) {
        fetch(originalSurvey.image)
          .then(async (response) => {
            if (response.status > 399) {
              throw await response.text();
            }
            return response.blob();
          })
          .then((blob) => {
            setSelectedFile(blob);
            setImage(URL.createObjectURL(blob));
          })
          .catch((err: Error) => {
            enqueueSnackbar(`failed to clone the image: ${err?.message || err}`, {
              variant: "error",
            });
          });
      }
      setSurveyReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalSurvey]);

  useEffect(() => {
    const id = searchParams.get("originalSurveyId");
    if (id) {
      setIsLoading(true);
      SurveysService.getSurvey(id)
        .then((resp) => {
          setOriginalSurvey(resp.data);
        })
        .catch((err) => {
          enqueueSnackbar(`failed to clone the survey: ${err.message}`, {
            variant: "error",
          });
        })
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setImage(URL.createObjectURL(file));
    }
  };

  const removeImage = () => {
    setSelectedFile(null);
    setImage(null);
  };

  const changeSourceView = () => {
    setIsSourceView(!isSourceView);
  };

  return searchParams.get("originalSurveyId") && !surveyReady ? (
    <>{"Loading..."}</>
  ) : (
    <Container
      sx={{
        marginTop: "60px",
      }}
      data-testid="admin-create-survey"
    >
      <Box>
        <Typography variant="h6">
          <VeraLink to="/admin/surveys">Back</VeraLink>
        </Typography>
      </Box>
      <Box className="tab">
        <div className={`tab-title ${!isSourceView ? `tab-title__disabled` : ""}`} onClick={changeSourceView}>
          Source
        </div>
        <div className={`tab-title ${isSourceView ? `tab-title__disabled` : ""}`} onClick={changeSourceView}>
          Redirect Links
        </div>
      </Box>
      <div className={isSourceView ? "" : "hidden"}>
        <SourceTab
          setSourceData={setSourceData}
          sourceData={sourceData}
          isLucidSurvey={isLucidSurvey}
          setIsLucidSurvey={setIsLucidSurvey}
        />
      </div>
      <div className={isSourceView ? "hidden" : ""}>
        <RedirectLinksTab
          multiPointPrefixDisabled={multiPointPrefixDisabled}
          setMultiPointPrefixDisabled={setMultiPointPrefixDisabled}
          setRedirectLinksData={setRedirectLinksData}
          redirectLinksData={redirectLinksData}
          redirectPointsData={redirectPointsData}
          setRedirectPointsData={setRedirectPointsData}
        />
      </div>
      <Box className="tab">
        <div className="tab-title">Survey Details</div>
      </Box>
      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          alignItems: "flex-start",
        }}
      >
        <PaperDefault>
          <form id="uploadForm" encType="multipart/form-data">
            <Divider sx={{ marginY: "24px" }} />

            <Typography variant="body1" fontWeight="bold">
              Configure Internal Parameters
            </Typography>

            <div style={{ marginTop: 8 }}>
              <span>Max image size: 1MB</span>
            </div>

            {image ? (
              <div className="upload-image-container">
                <div className="image-wrapper">
                  <img src={image} alt="survey-cover" />
                </div>
                <Button variant="outlined" onClick={removeImage} className="remove-image-button">
                  Remove Image
                </Button>
              </div>
            ) : (
              <div className="upload-image-container">
                <input
                  type="file"
                  accept="image/*"
                  id="upload-image-button"
                  onChange={onFileChange}
                  style={{ display: "none" }}
                />

                <label htmlFor="upload-image-button" className="upload-image-label">
                  <FileUploadOutlinedIcon />
                  <p>Upload Image</p>
                </label>
              </div>
            )}

            <LeftColumnFields
              tags={tags}
              setTags={setTags}
              points={points}
              setPoints={setPoints}
              startDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              endDate={endDate}
              title={title}
              setTitle={setTitle}
              category={category}
              setCategory={setCategory}
              enableSpinner={enableSpinner}
              setEnableSpinner={setEnableSpinner}
              onChangeSpinnerPercentage={onChangeSpinnerPercentage}
              spinnerPercentage={spinnerPercentage}
              status={status}
              setStatus={setStatus}
              saveSurveyChanges={saveChanges}
              isLoading={isLoading}
            />
          </form>
        </PaperDefault>
        <div className="preview-card-container">
          <Typography sx={{ marginBottom: "10px" }} variant="body1">
            Preview
          </Typography>
          <SurveyCard
            name={title}
            image={image}
            reward={points}
            upToReward={multiPointPrefixDisabled ? null : upToPointAmount}
            categories={tags}
            completed={false}
            isEditMode={true}
            isPreview={true}
          />
        </div>
      </Box>
    </Container>
  );
};

export default NewSurvey;
