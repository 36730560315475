import { useEffect, useState } from "react";
import SurveysService from "../../../../services/api/surveysService";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { convertDateStringToLocal } from "../../../../helpers/date";
import "./SurveyNotificationLogs.scss";
import { NotificationsTagTypeEnum, SurveyNotificationLog } from "../../../../types/Survey";
import { LoadingButton } from "@mui/lab";
import TZPicker from "src/components/TZPicker/TZPicker";
import { PickersTimezone } from "@mui/x-date-pickers";

type props = {
  surveyId?: string;
  targetingTags?: {
    _id: string;
    tag: string;
  }[];
};

const SurveyNotificationLogs = ({ surveyId, targetingTags }: props) => {
  const [allLogs, setAllLogs] = useState<SurveyNotificationLog[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [pickersTimezone, SetPickersTimezone] = useState<PickersTimezone>("America/Los_Angeles");
  const [timezone, setTimeZone] = useState<PickersTimezone>("America/Los_Angeles");
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState<number>();

  const tagId2Name =
    targetingTags?.reduce<Record<string, string>>((res, tag) => {
      res[tag._id] = tag.tag;
      return res;
    }, {}) || {};

  const fetchNotificationLogs = async (page: number) => {
    if (!surveyId) return;
    setIsInitialLoad(false);
    setLoading(true);
    const activities = await SurveysService.getSurveyNotificationLogs(surveyId, { page });
    setLoading(false);
    setAllLogs([...allLogs, ...activities.data.docs]);
    setTotalPages(activities.data.pages);
  };

  const loadMoreLogs = () => {
    const nextPage = page + 1;
    if (totalPages === undefined || nextPage <= totalPages) {
      fetchNotificationLogs(nextPage);
      setPage(nextPage);
    }
  };

  const loadMoreButtonDisabled = () => {
    return !isInitialLoad && (totalPages === undefined || totalPages <= page);
  };

  const getEmailSentTo = (log: SurveyNotificationLog): string => {
    if (log.payload.singleEmailToNotify) return log.payload.singleEmailToNotify;
    if (log.payload.notificationsTagType === NotificationsTagTypeEnum.TAG) {
      return `Tag : ${tagId2Name[log.payload.notificationsTagValue] || log.payload.notificationsTagValue}`;
    }
    return log.payload.notificationsTagType;
  };

  useEffect(() => {
    if (pickersTimezone === "system") {
      setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    } else {
      setTimeZone(pickersTimezone);
    }
  }, [pickersTimezone]);

  return (
    <Stack spacing={2}>
      {!isInitialLoad ? (
        <>
          {" "}
          <Typography align="center" variant="h6">
            Notification History{" "}
          </Typography>
          <TZPicker onTZChange={SetPickersTimezone} />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Email Sent Time</TableCell>
                  <TableCell>Template used</TableCell>
                  <TableCell>Email sent to</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allLogs.map((log, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {convertDateStringToLocal(new Date(log.payload.scheduledAt || log.createdAt).toISOString(), {
                        timeZone: timezone,
                        month: "short",
                        year: "numeric",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </TableCell>
                    <TableCell>{log.payload.emailTemplate.name}</TableCell>
                    <TableCell>{getEmailSentTo(log)}</TableCell>
                    <TableCell>{log.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
      {loading ? (
        <LoadingButton sx={{ padding: "10px 70px", margin: "40px 0" }} className="load-more-button" loading />
      ) : (
        <Button
          className="load-more-button"
          variant="outlined"
          onClick={() => loadMoreLogs()}
          disabled={loadMoreButtonDisabled()}
          sx={{
            textTransform: "capitalize",
            border: "1px solid #2EBDEE",
            borderRadius: "4px",
            padding: "10px 70px",
            margin: "40px 0",
            "&:hover": {
              background: "#2EBDEE",
              color: "#fff",
              opacity: "0.7",
            },
            "&:disabled": {
              border: "1px solid #91a3a9",
            },
          }}
        >
          {isInitialLoad ? "View Notification History" : "Load More"}
        </Button>
      )}
    </Stack>
  );
};

export default SurveyNotificationLogs;
